const buildFilterQuery = (filter) => {
  let filterQuery = ''
  if (filter.site !== '0' && filter.site > 0) {
    filterQuery += '&siteId=' + filter.site

    // additionally add name if defined
    if (filter.name.trim().length) {
      filterQuery += `&site=${filter.name}`
    }
  }
  if (filter.id) {
    filterQuery += '&id=' + filter.id
  }
  if (filter.type) {
    filterQuery += '&type=' + filter.type
  }
  if (filter.title) {
    filterQuery += '&title=' + filter.title
  }
  if (filter.identifier) {
    filterQuery += '&identifier=' + filter.identifier
  }
  return filterQuery
}

const buildHpManagementFilterQuery = (filter) => {
  let filterQuery = ''
  if (filter.contentBlocks.length > 0) {
    filter.contentBlocks.forEach(id => {
      filterQuery += `&id=${id}`
    })
  }
  if (filter.site) {
    filterQuery += `&siteId=${filter.site}`
  }
  return filterQuery
}

const buildQueryForManagedItems = (filter) => {
  let filterQuery = ''
  if (filter.site !== '0' && filter.site > 0) {
    filterQuery += '&siteId=' + filter.site
  }
  if (filter.categoryId) {
    filterQuery += '&categoryId=' + filter.categoryId
  }
  return filterQuery
}

export default {
  buildFilterQuery,
  buildHpManagementFilterQuery,
  buildQueryForManagedItems
}
