// Util
import { formatDate } from '@/helpers/date'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.category) {
    filterQuery += '&filter_in[category]=' + filter.category.map(category => category.id)
  }
  if (filter.service) {
    filterQuery += '&filter_eq[service]=' + filter.service
  }
  if (filter.headline) {
    filterQuery += '&filter_contains[headline]=' + filter.headline
  }
  if (filter.contentCreatedFrom) {
    filterQuery += `&filter_gte[contentCreated]=${formatDate(filter.contentCreatedFrom, 'start')}`
  }
  if (filter.contentCreatedTo) {
    filterQuery += `&filter_lte[contentCreated]=${formatDate(filter.contentCreatedTo, 'end')}`
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
