import Store from '@/store'
import { router } from '@/main'
import appConfig from '@/config'
import { customCropSettings } from '@/services/dam/DamCustomCropSettings'

export const PREDEFINED_DAM_USER_TASR_ID = 'c4703282-494f-4191-a5d8-b5d935bbbcf4'

export const PREDEFINED_DAM_USERS = {
  'c4703282-494f-4191-a5d8-b5d935bbbcf4': 'TASR'
  // 'nmh.cumulus': 'CUMULUS',
  // 'nmh.naxos': 'NAXOS'
}

export const PREDEFINED_DAM_PDF_USERS = {
  // 'nmh.tasr.pdf': 'TASR',
  // 'nmh.cumulus.pdf': 'CUMULUS',
  // 'nmh.naxos.pdf': 'NAXOS'
}

// STATIC CROPS
export const STATIC_CROP_320_240 = '320x240'

// ROUTES
export const GALLERY_LIST_ROUTE = 'gallery_list'
export const GALLERY_EDIT_ROUTE = 'gallery_edit'

const RATIO_THRESHOLD_ERROR = 0.01

const findSmallestCropSelectedByAuthor = media => {
  const foundRatioUsage = media.mediaRatioUsages.find(ratioUsage => ratioUsage.selectedByAuthor) ?? media.mediaRatioUsages[0]

  const crops = availableCrops(foundRatioUsage)

  const customCrop = customCropSettings(crops, media.mediaUsageType)
  if (customCrop.length) {
    return reduceSmallestCrop(customCrop)
  }
  return reduceSmallestCrop(crops)
}

const reduceSmallestCrop = (crops) => {
  return crops.reduce((previous, current) => {
    const prevWidth = +(previous.split('x')[0])
    const currWidth = +(current.split('x')[0])
    return prevWidth < currWidth ? previous : current
  })
}

const availableCrops = ({ availableDimensionsCsv, ratio }) => {
  const availableCrops = availableDimensionsCsv.split(';')
  const ratioFound = Store.getters['media/getRatioById'](ratio)

  if (!ratioFound) {
    return availableCrops
  }

  const validCrops = availableCrops.filter(dim => {
    const [width, height] = dim.split('x').map(Number)
    return isValidRatio(width, height, ratioFound.ratioX, ratioFound.ratioY)
  })
  return validCrops.length ? validCrops : availableCrops
}

const isValidRatio = (width, height, targetWidth, targetHeight) => {
  return Math.abs(width / height - targetWidth / targetHeight) < RATIO_THRESHOLD_ERROR // Allow minor floating point errors
}

const findFirstDefaultCrop = ({ dynamic } = { dynamic: false }) => {
  const damConfigInfo = Store.getters['dam/configInfo']
  return damConfigInfo.auxiliaryCropsPrefixes.find(prefix => prefix.includes('dynamic') === Boolean(dynamic))
}

const getCdnRelativePath = (asset, { dynamic } = { dynamic: false }) => {
  const foundCrop = findFirstDefaultCrop({ dynamic })
  const { cdnRelativePath } = asset.auxiliaryCrops.find(crop => crop.cdnRelativePath.startsWith(foundCrop))
  return cdnRelativePath
}

const getDamImagePathForAsset = (asset, { dynamic } = { dynamic: true }) => {
  return getDamImagePath(getCdnRelativePath(asset, { dynamic }), dynamic)
}

const getDamImagePathForMedia = (media, mediaUsageType, staticCrop = null) => {
  let thumbnailCrop
  if (mediaUsageType) {
    thumbnailCrop = Store.getters['media/getThumbnailCrops'](mediaUsageType)?.[0]
  }

  let crop
  if (staticCrop) {
    crop = staticCrop
  } else if (thumbnailCrop) {
    crop = thumbnailCrop
  } else if (media.mediaRatioUsages?.length > 0) {
    crop = findSmallestCropSelectedByAuthor(media)
  } else {
    crop = findFirstDefaultCrop()
  }

  if (
    !Store.getters.vlm &&
    (
      router.currentRoute.name === GALLERY_LIST_ROUTE ||
      router.currentRoute.name === GALLERY_EDIT_ROUTE ||
      staticCrop
    )
  ) {
    crop = STATIC_CROP_320_240 // https://newsandmedia.atlassian.net/browse/CMS-5635?focusedCommentId=92459
  }

  let fileName = null
  if (typeof media === 'string') {
    fileName = media
  } else {
    const { filename } = media.damMediaEmbed
    fileName = filename
  }
  const cdnRelativePath = fileName ? `${crop}/${fileName}` : media.damMediaEmbed.cdnRelativePath

  return getDamImagePath(cdnRelativePath)
}

const getDamImagePath = (cdnRelativePath, auxiliary = false) => {
  // const { imageCdnUri } = Store.getters['dam/configInfo']
  const ApiDamImagePath = `${appConfig.dam.apiUrl()}/Image`
  const imagePath = `${ApiDamImagePath}${auxiliary ? '/auxiliary' : ''}/${cdnRelativePath}`
  // this is to ensure the browser always shows an updated non-cached image
  //   the value in vuex is not updated all the time
  //   (e.g. it is optimized to not be updated while paginating in DAM list)
  const ignoreBrowserCache = `?ignoreBrowserCache=${Store.getters['dam/imageCache']}`
  return `${imagePath}${ignoreBrowserCache}`
}

export default {
  getCdnRelativePath,
  getDamImagePathForAsset,
  getDamImagePathForMedia
}
