import store from '@/store'

const CUSTOM_CROP_SETTINGS = {
  vlm: {
    article_hero: {
      width: {
        min: 350
      }
    },
    article_body_block: {
      width: {
        min: 200
      }
    }
  },
  nmh: {}
}

export const customCropSettings = (crops, mediaUsageType) => {
  const company = store.getters.company
  const setting = CUSTOM_CROP_SETTINGS[company][mediaUsageType]

  if (!setting) {
    return []
  }

  return crops.filter(crop => {
    const [width, height] = crop.split('x').map(Number)
    if (setting.width) {
      if (setting.width.min && width < setting.width.min) {
        return false
      } else if (setting.width.max && width > setting.width.max) {
        return false
      }
    }

    if (setting.height) {
      if (setting.height.min && height < setting.height.min) {
        return false
      } else if (setting.height.max && height > setting.height.max) {
        return false
      }
    }

    return true
  })
}
