import { MENU_EDITOR_STATE_ACTIVE } from '@/components/mixins/valueObject/MenuEditorTypeMixin'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.siteId) {
    filterQuery += '&filter_eq[siteId]=' + filter.siteId
  }
  if (filter.isActive !== undefined && filter.isActive !== null && filter.isActive !== 0) {
    const query = MENU_EDITOR_STATE_ACTIVE === filter.isActive ? 'true' : 'false'
    filterQuery += '&filter_eq[isActive]=' + query
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
