import { REDIRECT_STATE_ALIAS } from '@/components/mixins/valueObject/RedirectTypeMixin'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.siteId) {
    filterQuery += `&filter_eq[siteId]=${filter.siteId}`
  }
  if (filter.isAutoGenerated !== null && filter.isAutoGenerated !== 0) {
    const query = REDIRECT_STATE_ALIAS === filter.isAutoGenerated ? 'false' : 'true'
    filterQuery += '&filter_eq[isAutoGenerated]=' + query
  }
  if (filter.originalUrl) {
    filterQuery += `&filter_contains[originalUrl]=${filter.originalUrl}`
  }
  if (filter.alias) {
    filterQuery += `&filter_contains[alias]=${filter.alias}`
  }
  if (filter.validFrom) {
    filterQuery += `&filter_gte[reservedUntil]=${filter.validFrom}`
  }
  if (filter.validTo) {
    filterQuery += `&filter_lte[validFrom]=${filter.validTo}`
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
