import DateTimeService, { dateTimeNow } from '../DateTimeService'
import moment from 'moment'
import _, { cloneDeep } from 'lodash'
import ParserOnSave from '@/services/tinymce/ParserOnSave'
import CoreApi from '@/api/core'
import ArticleModel from '../../model/ArticleModel'
import ArticleHpTopicOffer from '@/model/ArticleHpTopicOffer'

const prepareApiRequest = function (editedArticle) {
  const article = _.cloneDeep(editedArticle)
  if (article.rubrics) {
    article.rubrics = article.rubrics.map(record => Number.isInteger(record) ? record : record.id)
  }
  if (article.expanded?.categories) {
    article.categories = article.expanded.categories.map(record => record.id)
  }
  if (article.expanded?.owners) {
    article.owners = article.expanded.owners.map(record => record.id)
  }
  if (article.expanded?.sharedUsers) {
    article.sharedUsers = article.expanded.sharedUsers.map(record => record.id)
  }
  if (article.expanded?.authors) {
    article.authors = article.expanded.authors?.map(record => record.id)
  }
  if (article.expanded?.doctors) {
    article.doctors = article.expanded.doctors.map(record => record.id)
  }
  article.heroImage = null
  if (article.expanded?.heroImage) {
    article.heroImage = article.expanded.heroImage.id
  }
  article.listingImage = null
  if (article.expanded?.listingImage) {
    article.listingImage = article.expanded.listingImage.id
  }
  if (article.expanded?.gallery) {
    article.gallery = article.expanded.gallery.filter(record => Boolean(record)).map(record => record.id)
  }
  if (article.expanded?.tags) {
    article.tags = article.expanded.tags.map(record => record.id)
  }
  if (article.orderDate === '') {
    article.orderDate = dateTimeNow()
  }
  if (article.publishedSince === '') {
    article.publishedSince = article.orderDate
  }
  if (article.publishedUntil === '') {
    delete article.publishedUntil
  }
  if (moment(article.orderDate) > moment(article.publishedSince)) {
    article.publishedSince = article.orderDate
  }
  if (article.flag && article.flag.updatedUntilDatetime === '') {
    article.flag.updatedUntilDatetime = DateTimeService.dateTimeMin
  }
  if (article.flag && article.flag.justNowUntilDatetime === '') {
    article.flag.justNowUntilDatetime = DateTimeService.dateTimeMin
  }
  delete article.articleEmbeds
  delete article.articleLinks
  delete article.routes
  delete article.firstPublishedDate
  if (article.setting && article.setting.scheduledPublish) {
    delete article.setting.scheduledPublish
  }
  if (article.setting && article.setting.abTested) {
    let variantA = article.abTestVariants.find(item => item.variant === 'a')
    if (variantA === undefined) {
      variantA = ArticleModel.abTestVariants[0]
      article.abTestVariants.push(variantA)
    }
    variantA.title = article.field.title
    if (article.expanded) {
      variantA.image = article.expanded.heroImage
    }
  } else if (isEmptyAbTestVariantTitle(article.abTestVariants)) {
    delete article.abTestVariants
  }
  if (article.hpTopicOffer) {
    article.hpTopicOffer = article.hpTopicOffer
      .filter((hpTopicOffer) => hpTopicOffer.site !== null)
      .map(hpTopicOffer => {
        // ensure valid model also for entity log version (unsaved article loaded as JSON)
        const item = { ...cloneDeep(ArticleHpTopicOffer), ...hpTopicOffer }
        /**
         * For NMH compatibility reasons, we need to calculate publishedSince from date and slot
         * contentBlockId is set only for VLM articles, so it is used to determine if the article is VLM
         */
        if (!item.contentBlockId && item.date && (item.slot || item.slot === 0)) {
          item.publishedSince = moment(item.date).add(item.slot, 'hours').toJSON()
        }
        return item
      })
  }
  if (article.articleBadges && article.articleBadges.length && article.articleBadges[0]?.id) {
    article.articleBadges = article.articleBadges.map((badge) => badge.id)
  }
  if (article.expanded) {
    delete article.expanded
  }

  return article
}

const getArticleUrl = function (article) {
  const route = article.routes.find((route) => {
    if (route.isMain) {
      return route
    }
  })
  if (route) {
    return route.absoluteUrl
  }
  return ''
}

const getGalleryUrl = function (article) {
  const route = article.routes.find((route) => {
    if (route.isMain) {
      return route
    }
  })
  if (route) {
    const url = new URL(route.absoluteUrl)
    return url.protocol + '//' + url.hostname + ':' + url.port + '/gal' + url.pathname
  }
  return ''
}

const isEmptyAbTestVariantTitle = function (variants) {
  if (variants === undefined) return true
  return variants.some((variant) => variant.title === '')
}

const getBodyBlocksFromOldEditor = async (originalArticle) => {
  const article = _.cloneDeep(originalArticle)
  article.field.bodyText = await ParserOnSave.parseAll(article.field.bodyText)
  return CoreApi().post('/ArticleBodyConverter/articleFieldToBlocksConversion', JSON.stringify(prepareApiRequest(article)))
    .then(response => {
      return response.data.bodyBlocks
    })
    .catch(error => {
      console.error(error)
      return []
    })
}

const getBodyBlocks = async (originalArticle) => {
  let blocks = originalArticle.bodyBlocks
  if (!originalArticle.setting.editedInNewEditor && !blocks?.length && originalArticle.field.bodyText) {
    blocks = await getBodyBlocksFromOldEditor(originalArticle)
  }
  // pass empty array in case of replacing with unsaved version that has empty blocks
  return blocks ?? []
}

export default {
  prepareApiRequest,
  getArticleUrl,
  getGalleryUrl,
  getBodyBlocks
}
