const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_in[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + encodeURIComponent(filter.title)
  }
  if (filter.slug) {
    filterQuery += '&filter_contains[slug]=' + encodeURIComponent(filter.slug)
  }
  if (filter.sites && (Number.isInteger(filter.sites) || filter.sites.length > 0)) {
    filterQuery += '&filter_in[site]=' + filter.sites
  }
  if (filter.parent && filter.parent > 0) {
    filterQuery += '&filter_eq[parent]=' + filter.parent
  }
  if (filter.level) {
    filterQuery += '&filter_eq[level]=' + filter.level
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
